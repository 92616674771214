

export default function gsapAnim(): void {
    
    const gsap = window.gsap;
    const ScrollTrigger = window.ScrollTrigger;

    if(gsap && ScrollTrigger) {
      if (
        typeof gsap !== "undefined" &&
        gsap &&
        typeof ScrollTrigger !== "undefined" &&
        ScrollTrigger
      ) {
        gsap.registerPlugin(ScrollTrigger);
        // Select all elements with the data-scroll-animation attribute
        const scrollElements = document.querySelectorAll("[lm-scroll-animation]") as NodeList;
    
        scrollElements.length &&
          scrollElements.forEach((element) => {
    
            let animationData;
            const htmlElement = element as HTMLElement;
            const attribute = htmlElement.getAttribute("lm-scroll-animation");
    
            // Parse the JSON data from the attribute
            if(attribute) {
              try {
                animationData = JSON.parse(attribute);
              } catch (error) {
                console.error(error);
              }
            }
    
            const {
              axis = "y", // Default axis is "y"
              distance = 0, // Default distance is 0 (no movement)
              trigger = element, // Default trigger is the element itself
              current = 0, // Default current is 0
              start = "top bottom", // Default start is "top bottom"
              end = "bottom top", // Default end is "bottom top"
            } = animationData;
    
            gsap.fromTo(
              element,
              { [axis]: current },
              {
                [axis]: distance,
                ease: "power3.out",
                scrollTrigger: {
                  trigger: document.querySelector(trigger),
                  start: start,
                  end: end,
                  scrub: true,
                  toggleActions: "play none none reverse"
                },
              }
            );
          });
    
        // Button Animation
        const movableButtons = document.querySelectorAll("[lm-movable-button]");
    
        movableButtons.length &&
          movableButtons.forEach((movableButton) => {
            const moveAnchor = (e: MouseEvent) => {
              const { clientX, clientY } = e;
              const movableButtonContainer = movableButton as HTMLElement;
              const { left, top, width, height } =
                movableButtonContainer.getBoundingClientRect();
    
              const moveX = ((clientX - left - width / 2) / width) * 20;
              const moveY = ((clientY - top - height / 2) / height) * 20;
    
              gsap.to(movableButton, {
                x: moveX,
                y: moveY,
                duration: 0.3,
                ease: "power3.out",
              });
            };
    
            const resetAnchor = () => {
              gsap.to(movableButton, {
                x: 0,
                y: 0,
                duration: 0.3,
                ease: "power3.out",
              });
            };
    
            movableButton.addEventListener("mouseenter", () => {
              window.addEventListener("mousemove", moveAnchor);
            });
    
            movableButton.addEventListener("mouseleave", () => {
              window.removeEventListener("mousemove", moveAnchor);
              resetAnchor();
            });
          });
      } 
    } else {
      console.error(
        "GSAP or Scroll Trigger is not defined. Please ensure it is loaded correctly."
      );
    }
}