

export default function initWhatsAppButton(
    whatsappNumber: string, 
    backgroundColor: string, 
    icon?: string
) {
    const whatsappBtn = document.createElement("button");
    const img = document.createElement("img");
    img.src =
    icon ||
    "https://lmsamediaprodeastasia.blob.core.windows.net/landing-sdk/whatsapp-icon.png";
    img.alt = "icon";
    whatsappBtn.appendChild(img);
    whatsappBtn.id = "whatsappBtn";

    whatsappBtn.style.backgroundColor = backgroundColor || "#25d366";

    whatsappBtn.addEventListener("click", function () {
        window.open(`https://wa.me/${whatsappNumber || ""}`, "_blank");
    });

    document.body.appendChild(whatsappBtn);

}