export default function initCallButton(
    phoneNumber: string, 
    backgroundColor: string, 
    icon: string
) {
    const callBtn = document.createElement("button");
    const img = document.createElement("img");
    img.src =
    icon ||
    "https://lmsamediaprodeastasia.blob.core.windows.net/landing-sdk/call-icon.png";
    img.alt = "icon";
    callBtn.appendChild(img);
    callBtn.id = "callBtn";

    callBtn.style.backgroundColor = backgroundColor || "#28a745";

    callBtn.addEventListener("click", function () {
        window.location.href = `tel:${phoneNumber || ""}`;
    });

    document.body.appendChild(callBtn);

}