import { StickyBanner } from "./type";

export default function initStickyBanner({
    text,
    buttonText,
    buttonLink,
    backgroundColor,
    buttonTextColor,
    buttonBackgroundColor,
    textColor,
    showBanner   
}: StickyBanner) {
    
    if (!showBanner) return;

    let header = document.querySelector("header");

    if (!header) {
    header = document.createElement("header");
    document.body.prepend(header);
    }

    header.style.background = backgroundColor || "#fff";

    // Create the sticky banner container
    const banner = document.createElement("div");
    banner.className = "sticky-banner";
    banner.style.color = textColor || "#000";

    const bannerContent = document.createElement("div");
    bannerContent.className = "sticky-banner-content";

    const bannerText = document.createElement("span");
    bannerText.textContent = text || "This is a sticky banner";

    bannerContent.appendChild(bannerText);

    if (buttonLink && buttonLink !== "") {
    const bannerButton = document.createElement("a");
    bannerButton.href = buttonLink;
    bannerButton.textContent = buttonText || "Learn More";
    bannerButton.className = "banner-button";   
    bannerButton.style.backgroundColor = buttonBackgroundColor || "#007bff";
    bannerButton.style.color = buttonTextColor || "#fff";
    bannerContent.appendChild(bannerButton);
    }

    banner.appendChild(bannerContent);
    header.appendChild(banner);
    
}