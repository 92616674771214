import { 
    FAQItem, 
    FAQProps
} from "./type";

export default function initFaq(
    {
        faq,
        title= "Frequently Asked Questions",
        icon ="",
        addSchema = true
    }: FAQProps
): void {

    const faqItems = Array.isArray(faq) ? faq : faq.items;

    /* First create the container elements */
    const faqMainContainer = document.querySelector(".faq-main-container") as HTMLElement;
    const faqTitle = document.querySelector(".faq-title") as HTMLElement;
    const faqItemsContainer = document.querySelector(".faq-items-container") as HTMLElement;

    if(!faqMainContainer || !faqTitle || !faqItemsContainer) {
        throw new Error("Could not find elements");
    }

    if(faqItems.length === 0) {
        console.log("No FAQ data");
        return;
    }

    // append data to the container elements
    faqTitle.textContent = title;

    // Create item elements
    faqItems.forEach((item: FAQItem) => {

        // create item container
        const faqItemContainer = document.createElement("li");
        const faqQuestionContainer = document.createElement("div");
        const question = document.createElement("h3");
        const iconContainer = document.createElement("img");
        const answerContainer = document.createElement("div");
        const answer = document.createElement("p");

        // add class names
        faqItemContainer.className = "faq-item";
        faqQuestionContainer.className = "faq-question-container";
        question.className = "faq-question";
        iconContainer.className = "faq-icon";
        answerContainer.className = "faq-answer";

        // add text content to elements
        question.textContent = item.question;
        iconContainer.setAttribute("src", icon ? icon : "");
        iconContainer.setAttribute("alt", "Toggle icon");
        answer.textContent = item.answer;

        faqItemContainer.append(faqQuestionContainer, answerContainer); // appended multiple items to item container
        faqQuestionContainer.append(question, iconContainer); // append multiple items into question contianer
        answerContainer.appendChild(answer);


        // boolean to track click
        let isOpen = false;

        // Faq Item click event listener
        faqItemContainer.addEventListener("click", () => {

            if(!isOpen) {
                answerContainer.classList.add("open");
                iconContainer.classList.add("rotate");
                isOpen = true;
            } else {
                answerContainer.classList.remove("open");
                iconContainer.classList.remove("rotate");
                isOpen = false;
            }

        });

        faqItemsContainer.appendChild(faqItemContainer); 

    });

    if(addSchema) {

        // Generate the JSON-LD FAQ schema dynamically
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqItems.map((item) => ({
                "@type": "Question",
                name: item.question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: item.answer,
                },
            }))
        };


       const schemaScript = document.createElement("script") as HTMLScriptElement;

       schemaScript.id = "faq-schema";
       schemaScript.type = "application/ld+json";
       schemaScript.innerHTML = JSON.stringify(faqSchema)
       document.body.appendChild(schemaScript);

    }


}
