export function addGTM(tagId: string): void {

    if(!tagId) {
        console.error("GTM Tag ID is required");
        return;
    }

    // Check if GTM script is already included

    if(!document.getElementById("gtmScript")) {
        (
            function(w: Window, d: Document, s: string, l: string, i: string) {

                w.dataLayer = w.dataLayer || [];
                w.dataLayer.push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js"
                });

                const f = d.getElementsByTagName(s)[0];
                const j = d.createElement(s) as HTMLScriptElement;
                const dl = l != "dataLayer" ? "&l=" + l : "";

                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                j.id = "gtmScript"; // Add an ID to the script tag to prevent multiple inclusions
                f.parentNode?.insertBefore(j, f);
            }
        )(window, document, "script", "dataLayer", tagId);
    }

}

export function setCopyRightWithUTM(currentUrl: string, campaignName: string): void {

    // Encode the current URL to be used in the UTM parameters
    const encodedUrl = encodeURIComponent(currentUrl);
    // Construct the UTM URL
    const utmUrl = `https://leadmetrics.ai?utm_source=${encodedUrl}&utm_medium=landingpage&utm_content=footer&utm_campaign=${encodeURIComponent(
        campaignName
    )}`;

    // Create the copyright element with the link
    const copyrightHtml = `
        <p>&copy; ${new Date().getFullYear()} All rights reserved.
              Digital Marketing with AI powered by
        <a href="${utmUrl}" target="_blank" rel="noopener noreferrer">Leadmetrics</a></p>
    `;

    const container = document.getElementById("copyright");
    
    if(container) container.innerHTML = copyrightHtml;

}
