import initStickyBanner from "./banner";
import initCallButton from "./buttons/call";
import initScrollToTop from "./buttons/scroll-to-top";
import initWhatsAppButton from "./buttons/whatsapp";
import initFaq from "./faq";
import gsapAnim from "./gsap";
import initModal from "./modal";
import "./styles.scss";
import { LandingControls } from "./type";
import { addGTM, setCopyRightWithUTM } from "./utils/utils";

declare global {
  interface Window {
    lmControls: LandingControls;
    gsap?: any;
    ScrollTrigger?: any;
    dataLayer: any[];
  }
}

window.lmControls = {
  initScrollToTop,
  initCallButton,
  initWhatsAppButton,
  initModal,
  initStickyBanner,
  gsapAnim,
  addGTM,
  setCopyRightWithUTM,
  initFaq
}
