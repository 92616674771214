
export default function initModal(
    title: string,
    message: string,
    color: string,
    buttonLink: string,
    buttonText: string,
    showModal: boolean = false
) {
    const modal = document.createElement("div");
      modal.id = "customModal";
      modal.className = "modal";

      const modalContent = document.createElement("div");
      modalContent.className = "modal-content";

      const modalTitle = document.createElement("h2");
      modalTitle.className = "modal-title";
      modalTitle.textContent = title || "Thank You!";
      modalTitle.style.color = color || "#0275d8";

      const modalMessage = document.createElement("p");
      modalMessage.className = "modal-message";
      modalMessage.textContent = message || "We will contact you soon";

      if (buttonLink && buttonLink != "") {
        const modalButton = document.createElement("button");
        modalButton.className = "modal-button";
        modalButton.textContent = buttonText || "Go to Home";
        modalButton.style.backgroundColor = color || "#0275d8";
        modalContent.appendChild(modalButton);

        modalButton.addEventListener("click", () => {
          window.location.href = buttonLink;
          modal.classList.add("modal-hidden");
        });
      } else {
        const closeButton = document.createElement("button");
        closeButton.id = "closeButton";
        closeButton.className = "modal-close";
        closeButton.title = "Close";
        closeButton.innerHTML = `<img src="https://lmsamediaprodeastasia.blob.core.windows.net/landing-sdk/close.png" alt="close" />`;
        modalContent.appendChild(closeButton);

        closeButton.addEventListener("click", () => {
          modal.classList.add("modal-hidden");
          window.location.href = "/";
        });
      }

      modalContent.appendChild(modalTitle);
      modalContent.appendChild(modalMessage);

      modal.appendChild(modalContent);
      document.body.appendChild(modal);

      // Function to show the modal
      function displayModal() {
        modal.classList.remove("modal-hidden")
        modal.style.display = "flex";
      }

      // Function to hide the modal
      function hideModal() {
        modal.classList.add("modal-hidden");
      }

      if (showModal) {
        displayModal();
      } else {
        hideModal();
      }
}